<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link class="navbar-brand" to="/">
          <span class="brand-logo">
            <b-img v-if="isDark" :src="appLogoImage" alt="logo" />
            <b-img v-else :src="darkAppLogoImage" alt="logo" />
          </span>
          <!-- <h3 class="mb-0 ml-">{{appName}}</h3> -->
          <!-- <h2 class="brand-text mb-0">
            {{ appName }}
          </h2> -->
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'

export default {
  components: {
    BLink,
    BImg
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')
    // App Name
    const { appName, appLogoImage, darkAppLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
      darkAppLogoImage,
      skin,
      isDark
    }
  }
}
</script>

<style></style>
