export default [
  {
    header: 'Dashboard'
  },
  {
    title: 'Analytics',
    route: 'analytics',
    icon: 'ActivityIcon'
  },
  {
    title: 'Matters',
    route: 'matters',
    icon: 'ServerIcon'
  },
  // {
  //   title: 'Court Hearing',
  //   route: 'court-hearing',
  //   icon: 'CalendarIcon'
  // },
  {
    title: 'Causelist',
    route: 'cause-list',
    icon: 'FileTextIcon'
  },
  {
    title: 'Display Board',
    route: 'display-board',
    icon: 'LayoutIcon'
  },
  {
    title: 'FIR Tracker',
    route: 'firtracker',
    icon: 'ClockIcon'
  },
  {
    title: 'Legal Updates',
    route: 'lawtrend-news',
    icon: 'BookOpenIcon'
  }
]
